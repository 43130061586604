import { FC, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

import { GraphData, GraphDataReduced } from "../types/GraphData";

interface FilaGrafProps {
  filtroFilial: number;
  setor: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export const options = {
  responsive: false,
  parsing: {
    xAxisKey: "created_at",
    yAxisKey: "min_senha",
  },
  plugins: {
    legend: {
      display: false,
      // position: "top" as const,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      type: "time" as const,
      time: {
        unit: "hour" as const,
      },
    },
    y: {
      min: 0,
      suggestedMax: 30,
    },
  },
};

const FilaGraf: FC<FilaGrafProps> = (props): JSX.Element => {
  const url = process.env.REACT_APP_API_URL + "fila-setores/historico";
  const [data, setData] = useState<GraphDataReduced[]>([]);

  const filterJson = (json: Array<GraphData>) => {
    if (props.setor === "acougue") {
      return json.map((item) => ({
        min_senha:
          item.min_acougue_geral > item.min_acougue_pref
            ? item.min_acougue_geral
            : item.min_acougue_pref,
        created_at: new Date(item.created_at),
        nm_filial: item.nm_filial,
      }));
    } else {
      return json.map((item) => ({
        min_senha:
          item.min_frios_geral > item.min_acougue_pref
            ? item.min_acougue_geral
            : item.min_acougue_pref,
        created_at: new Date(item.created_at),
        nm_filial: item.nm_filial,
      }));
    }
  };

  useEffect(() => {
    const getData = async () => {
      fetch(
        url + `?loja=${props.filtroFilial}` //&date=${encodeURIComponent("12/24/2023")}`
      )
        .then((res) => res.json())
        .then((json) => {
          setData(filterJson(json));
        });
    };

    getData();
  }, []);
  const graphData = {
    datasets: [
      {
        label: "Tempo",
        // data: data,
        data: data.filter((item: any) => {
          return item.created_at.getHours() > 7;
        }),
        borderColor: "rgb(236, 103, 14)",
        backgroundColor: "rgba(236, 103, 14, 0.5)",
        pointRadius: 0,
      },
    ],
  };
  return <Line options={options} data={graphData} />;
};
export default FilaGraf;
