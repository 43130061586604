import React, { useState, useEffect } from "react";
import logo from "./assets/logo.png";
import "./App.css";

import WaitingLineGauge from "./components/FilaSetoresComponent";
import WaitingLine from "./types/WaitingLine";

function App() {
  const url = process.env.REACT_APP_API_URL + "fila-setores";

  const [data, setData] = useState<WaitingLine[]>([]);

  useEffect(() => {
    const getData = async () => {
      fetch(url)
        .then((res) => res.json())
        .then((json) => {
          setData(
            json.sort(
              (a: WaitingLine, b: WaitingLine) =>
                parseInt(a.status_nr_filial) - parseInt(b.status_nr_filial)
            )
          );
        });
    };

    getData();

    const interval = setInterval(() => {
      getData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* <div className="Container-setor Acougue">
          <h1>Acougue</h1> */}
        <div className="grid-container">
          {data.map((store, index) => {
            return (
              <div
                className="grid-item"
                key={"acougue-" + store.status_nm_filial}
                // style={{ borderColor: props.setor === "acougue" ? "red" : "yellow" }}
                style={{ borderColor: "red" }}
              >
                <WaitingLineGauge
                  min_geral={store.status_min_acougue_geral}
                  min_pref={store.status_min_acougue_pref}
                  qnt_geral={store.status_qnt_acougue_geral}
                  qnt_pref={store.status_qnt_acougue_pref}
                  setor={"acougue"}
                  ult_log={store.status_created_at}
                  filial={store.status_nm_filial}
                  nr_filial={Number(store.status_nr_filial)}
                />
              </div>
            );
          })}
        </div>
        {/* </div> */}
        {/* <div className="Container-setor Frios">
          <h1>Frios</h1> */}
        <div className="grid-container">
          {data.map((store, index) => {
            return (
              <div
                className="grid-item"
                key={"frios-" + store.status_nm_filial}
                style={{ borderColor: "yellow" }}
              >
                <WaitingLineGauge
                  min_geral={store.status_min_frios_geral}
                  min_pref={store.status_min_frios_pref}
                  qnt_geral={store.status_qnt_frios_geral}
                  qnt_pref={store.status_qnt_frios_pref}
                  setor={"frios"}
                  ult_log={store.status_created_at}
                  filial={store.status_nm_filial}
                  nr_filial={Number(store.status_nr_filial)}
                />
              </div>
            );
          })}
        </div>
        {/* </div> */}
      </header>
    </div>
  );
}

export default App;
