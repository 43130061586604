import GaugeComponent from "react-gauge-component";
import { FC } from "react";
import React from "react";
// import Modal from "react-modal";
import FilaGraf from "./FilaGrafComponent";
import "./FilaSetoresComponent.css";
// import infoIcon from "../assets/info-icon.png";
import graphIcon from "../assets/graph-icon.png";
import peopleIcon from "../assets/people-icon.png";

interface GaugeProps {
  min_geral: number;
  min_pref: number;
  qnt_geral: number;
  qnt_pref: number;
  setor: string;
  ult_log: string;
  filial: string;
  nr_filial: number;
}

const calculateStatus = (ult_log: string) => {
  if ((Date.now() - new Date(ult_log).getTime()) / 1000 / 60 < 3) {
    return "green";
  }
  return "red";
};

const maxWaiting = (tempo_geral: number, tempo_pref: number) => {
  if (tempo_geral > tempo_pref) {
    return tempo_geral;
  } else {
    return tempo_pref;
  }
};

const WaitingLineGauge: FC<GaugeProps> = (props): JSX.Element => {
  // const [modalGraphIsOpen, setGraphIsOpen] = React.useState(false);
  // const [modalInfoIsOpen, setInfoIsOpen] = React.useState(false);
  const [graphOpen, setGraphOpen] = React.useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);

  const toggleGraph = () => {
    setInfoOpen(false);
    setGraphOpen(!graphOpen);
  };

  // function openModalGraph() {
  //   setGraphIsOpen(true)
  // }

  // function closeModalGraph() {
  //   setGraphIsOpen(false);
  // }

  return (
    <div className="Container-box">
      <div className="Container-header">
        <div className="Header-icon" onClick={toggleGraph}>
          <img
            className="Gauge-icon"
            src={graphIcon}
            alt="Grafico"
            // onClick={toggleGraph}
          />
        </div>
        <div className="Container-Nome">
          <p className="Nome-filial">{props.filial}</p>
        </div>
        <div className="Header-icon">
          <p
            className="Status-carga-icon"
            style={{
              color: calculateStatus(props.ult_log),
            }}
          >
            ●
          </p>
        </div>
        {/* <img
          className="Gauge-icon"
          src={infoIcon}
          alt="Info"
          onClick={toggleInfo}
        /> */}
      </div>
      {/* <Modal
        isOpen={modalGraphIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalGraph}
        contentLabel="Modal Gráfico"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="Modal-content">
          <h1>Em desenvolvimento</h1>
        </div>
      </Modal>
      <Modal
        isOpen={modalInfoIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalInfo}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="Modal-content">
          <h3> Senhas Geral: {props.qnt_geral}</h3>
          <h3> Tempo Geral: {props.min_geral} min</h3>
          <h3> Senhas Preferencial: {props.qnt_pref}</h3>
          <h3> Tempo Preferencial: {props.min_pref} min</h3>
          <button onClick={closeModalInfo}>close</button>
        </div>
      </Modal> */}
      <div className="Container-content">
        <GaugeComponent
          marginInPercent={{
            top: 0.07,
            bottom: 0.0,
            left: 0.07,
            right: 0.07,
          }}
          arc={{
            subArcs: [
              {
                limit: 6.25,
                color: "#5BE12C",
                showTick: false,
              },
              {
                limit: 12.5,
                color: "#F5CD19",
                showTick: false,
              },
              {
                limit: 18.75,
                color: "#F58B19",
                showTick: false,
              },
              {
                limit: 25,
                color: "#EA4228",
                showTick: false,
              },
            ],
          }}
          labels={{
            valueLabel: {
              matchColorWithArc: true,
              style: { fontSize: "75vh" },
            },
            tickLabels: {
              hideMinMax: true,
            },
          }}
          maxValue={
            maxWaiting(props.min_geral, props.min_pref) > 25
              ? maxWaiting(props.min_geral, props.min_pref)
              : 25
          }
          value={maxWaiting(props.min_geral, props.min_pref)}
        />
      </div>
      <div className="Container-footer">
        <div className="Footer-Icon">
          <img className="People-icon" src={peopleIcon} alt="Pessoas" />
        </div>
        <div className="vl"></div>
        <div className="Info-quantidade">
          <span>G: {props.qnt_geral}</span>
        </div>
        <div className="vl"></div>
        <div className="Info-quantidade">
          <span>P: {props.qnt_pref}</span>
        </div>
      </div>
      {graphOpen ? (
        <FilaGraf filtroFilial={props.nr_filial} setor={props.setor} />
      ) : null}
    </div>
  );
};
export default WaitingLineGauge;
